.overlay {
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  position: fixed;
  background: rgb(255, 255, 255, 0);
}
.overlayInner {
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  position: absolute;
}
.overlayContent {
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
}

.loader {
  border: 10px solid transparent;
  border-radius: 50%;
  border-top: 10px solid #fc0;
  border-right: 10px solid #ffcc00;
  border-bottom: 10px solid #ffcc00;
  width: 75px;
  height: 75px;
  animation: spin 1s linear infinite;
  -webkit-animation: spin 1s linear infinite;
  animation-name: spin;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}
