.actionIcons {
  float: right;
  opacity: 0;
  cursor: pointer;
}

.form-group:hover .actionIcons {
  opacity: 10;
}

.dropOver {
  border: var(--warning) 1px solid;
}

.dragging {
  opacity: 0.5;
}

.settings {
  position: absolute !important;
  left: 293px;
  top: -26px;
  min-width: 300px !important;
  z-index: 999;
  border: 1px solid !important;
  border-color: var(--default);
}

.settings:before {
  content: "";
  display: inline-block;
  width: 12px;
  height: 12px;
  background: white;
  position: absolute;
  border-top: 1px solid var(--default);
  border-right: 1px solid var(--default);
  left: -7px;
  top: 30px;
  transform: rotate(-135deg);
}
.ezFormBgcolor{
  background-color: rgb(248, 236, 170) ;
}