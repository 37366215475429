.actionBtns {
  float: right;
}

.reportRow {
  cursor: pointer;
}

.reportRow:hover {
  background: var(--lighter);
}
