.card {
  box-shadow: 0 0 2rem 0 rgba(136, 152, 170, 50%) !important;
}

.card-header {
  border: none;
}

.card-body {
  border-top: 1px solid rgba(0, 0, 0, 0.2);
}

.actionBtns {
  float: right;
  cursor: pointer;
}

label.warning {
  color: var(--warning);
}

label.success {
  color: var(--success);
}

label.warning {
  color: var(--warning);
}

label.danger {
  color: var(--danger);
}
