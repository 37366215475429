.icon-button {
  width: 12rem;
  height: 5rem;
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.icon-button div:nth-child(2) {
  margin-top: 0.5rem;
  height: 2rem;
}

.card-body {
  min-height: 10rem;
}

.fa-lg {
  position: absolute;
  top: 20px;
  left: 100px;
}

.actionBtns {
  float: right;
}
