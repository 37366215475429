.actionBtns {
  float: right;
}

.formRow {
  cursor: pointer;
}

.formRow:hover {
  background: var(--lighter);
}
