.components {
  position: sticky;
  top: 0;
}

.card {
  box-shadow: 0 0 2rem 0 rgba(136, 152, 170, 50%);
}

.form-group,
.form-group * {
  cursor: grab;
}

.dragging {
  opacity: 0.5;
}
